
export default {
  name: 'HeadlineBlok',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    vPadding() {
      const value = this.blok.vertical_padding ? this.blok.vertical_padding : 0
      return value >= 0 ? value : 'n' + -value
    },
    hPadding() {
      const value = this.blok.vertical_padding ? this.blok.vertical_padding : 0
      return value >= 0 ? value : 'n' + -value
    },
    style() {
      if (this.blok.fontweight) {
        return 'font-weight:' + this.blok.fontweight + '!important'
      }
      return {}
    },
  },
}
